import { BrandVariablesFragment } from '@/lib/graphql/shared';
import { createContext } from 'react';

export interface TenantFeatures {
  enableCronofyScheduling?: boolean;
  enableNonIntegratedScheduling?: boolean;
  enableHomeView?: boolean;
  enableJourneyView?: boolean;
}

export interface EnrollmentConfig {
  readonly steps: [string, ...string[]];
}

/**
 * We parse the tenant data object in order to provide a cleaner configuration object where possible.
 */
export interface TenantConfiguration {
  id: string;
  domain: string;
  name: string;
  slug: string;
  features: TenantFeatures;
  branding: BrandVariablesFragment | null;
  cognito: {
    clientId: string;
    userPoolId: string;
  };
  mapboxConfig: {
    accessToken?: string;
  };
  contentfulConfig: {
    spaceId: string;
    environment: string;
    accessToken: string;
  };
  enrollmentConfig: EnrollmentConfig;
}

export interface TenantConfigurationProps {
  config: TenantConfiguration | null;
  setConfig: (config: TenantConfiguration) => void;
}

// We use `never` and a cast, to ensure that `TenantConfigContext.Provider` can not be used without a value.
export const TenantConfigContext = createContext<
  TenantConfigurationProps | never
>(null as never);
