import { FunctionComponent, PropsWithChildren } from 'react';
import {
  TenantConfigContext,
  TenantConfiguration,
} from './TenantConfigContext';
import tenantConfigExtensions from '@/lib/config-tenant-extensions';

export interface MockTenantConfigProviderProps extends PropsWithChildren {
  config?: Omit<Partial<TenantConfiguration>, 'features'> & {
    features?: Partial<TenantConfiguration['features']>;
  };
}

/**
 * Mock Provider for tenant configuration, sets static defaults for testing the application,
 * along with passing in any custom configuration overrides that may be needed by features.
 */
export const MockTenantConfigProvider: FunctionComponent<
  MockTenantConfigProviderProps
> = ({ config = {}, children }) => {
  const mockConfig: TenantConfiguration = {
    id: '37dd2973-9150-4f89-94ba-8a256853046f',
    domain: 'test.tenant.example.com',
    name: 'Test Tenant',
    slug: 'huntington',
    branding: null,
    cognito: {
      clientId: 'test-client-id',
      userPoolId: 'test-pool-id',
    },
    mapboxConfig: {
      accessToken: 'test-token',
    },
    ...tenantConfigExtensions['huntington'],
    ...config,
    features: {
      enableCronofyScheduling: false,
      enableNonIntegratedScheduling: false,
      enableHomeView: false,
      enableJourneyView: false,
      ...(config.features ?? {}),
    },
    contentfulConfig: {
      accessToken: 'test-token',
      environment: 'master',
      spaceId: 'test-space',
    },
  };

  return (
    <TenantConfigContext.Provider
      value={{ config: mockConfig, setConfig: () => {} }}>
      {children}
    </TenantConfigContext.Provider>
  );
};
